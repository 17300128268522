<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="10"
        >
          <b-card
            no-body
            class="mb-0 mt-3"
          >
            <b-card-title
              class="d-flex justify-content-between mt-1 mx-2 mb-0"
            >
              <div
                class="d-flex align-items-center py-50"
              >
                <svg-icon
                  type="mdi"
                  :path="mdiFileDocumentMultipleOutline"
                  class="mr-50"
                />
                <span class="d-inline">Documentos</span>
              </div>
            </b-card-title>
            <b-table
              class="position-relative mt-50"
              primary-key="id"
              responsive
              show-empty
              striped
              hover
              empty-text="Não há documentos cadastrados."
              :items="companyDocuments"
              :fields="tableColumns"
              @row-clicked="onRowSelected"
            >
              <template #cell(id)="data">
                <b-media vertical-align="center">
                  <b-link
                    class="font-weight-bold d-block text-nowrap"
                    @click="onRowSelected(data.item)"
                  >
                    #{{ data.item.id }}
                  </b-link>
                </b-media>
              </template>

              <template #cell(field_document_name)="data">
                <b-media vertical-align="center">
                  <div
                    class="d-block text-nowrap font-weight-bold"
                  >
                    {{ data.item.document_name }}
                  </div>
                </b-media>
              </template>

              <template #cell(field_status)="data">
                <div
                  class="d-block text-nowrap"
                >
                  <b-badge
                    pill
                    class="text-capitalize"
                    :style="{
                      'background-color': getCompanyStatus(data.item.date_validity) === 'Vencido' ? '#E2445C' : '#219753'
                    }"
                  >
                    {{ getCompanyStatus(data.item.date_validity) }}
                  </b-badge>
                </div>
              </template>

              <template #cell(field_date_emission)="data">
                <b-media vertical-align="center">
                  <div
                    v-if="data.item.date_emission"
                    class="d-block text-nowrap"
                  >
                    {{ moment(data.item.date_emission).format('DD/MM/YYYY') }}
                  </div>
                  <div v-else>
                    <span class="font-weight-bold">-</span>
                  </div>
                </b-media>
              </template>

              <template #cell(field_date_validity)="data">
                <div
                  v-if="data.item.date_validity"
                  class="d-block text-nowrap"
                >
                  {{ moment(data.item.date_validity ).format('DD/MM/YYYY') }}
                </div>
                <div v-else>
                  <span class="font-weight-bold">-</span>
                </div>
              </template>

              <template #cell(field_link_certificates)="data">
                <div
                  v-if="data.item.link_certificates"
                  class="d-block text-nowrap"
                >
                  {{ data.item.link_certificates }}
                </div>
                <div v-else>
                  <span class="font-weight-bold">-</span>
                </div>
              </template>

              <template #cell(field_actions)="data">
                <div>
                  <b-dropdown
                    variant="link"
                    no-caret
                    :dropup="true"
                    :boundary="'scrollParent'"
                    :right="$store.state.appConfig.isRTL"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="!hasPermissionToUpdate"
                      :to="{ name: 'company-detail', params: { id: data.item.id } }"
                    >
                      <feather-icon icon="FileTextIcon" />
                      <span class="align-middle ml-50">Detalhes</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="hasPermissionToUpdate"
                      @click="onRowSelected(data.item)"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Editar</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      :disabled="!hasPermissionToUpdate"
                      @click="showConfirmOrCancelDelete(data.item)"
                    >
                      <svg-icon
                        type="mdi"
                        size="16"
                        :path="mdiTrashCanOutline"
                      />
                      <span class="align-middle ml-50">Remover</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-2">
        <b-col
          class="col d-flex justify-content-end"
          cols="12"
          md="12"
          lg="10"
        >
          <b-button
            id="companyBackBtn"
            variant="outline-primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :to=" { name: 'company-detail', params: { id: companyId } } "
          >
            Voltar
          </b-button>
        </b-col>
      </b-row>
      <validation-observer
        v-slot="{ handleSubmit, invalid }"
        ref="refFormObserver"
      >
        <b-modal
          id="idModalAddCompanyDocument"
          ref="modalAddCompanyDocument"
          no-close-on-backdrop
          no-close-on-esc
          centered
          cancel-variant="outline-primary"
          size="lg"
          :ok-disabled="invalid"
          @ok="handleSubmit(save)"
          @cancel="cancel()"
          @hidden="cancel()"
        >
          <template #modal-title>
            <div
              style="color: #005C96"
            >
              <svg-icon
                type="mdi"
                size="30"
                :path="mdiClipboardEditOutline"
              />
              <span
                class="font-weight-bolder"
              >
                Cadastro de Documentos
              </span>
            </div>
          </template>
          <template #modal-cancel>
            Cancelar
          </template>
          <template #modal-ok>
            Salvar
          </template>
          <b-form
            class="mt-1"
          >
            <b-row>
              <b-col
                class="pr-50"
              >
                <validation-provider
                  #default="validationContext"
                  name="document"
                  :rules="{
                    required: true,
                  }"
                >
                  <b-form-group
                    label="Documento"
                    label-for="document"
                  >
                    <b-form-input
                      id="document"
                      v-model="value.document_name"
                      name="document"
                      placeholder="Documento"
                      :readonly="!hasPermissionToUpdate"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                class="px-50"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="date_emission"
                  :rules="{
                    required: true,
                  }"
                >
                  <b-form-group
                    label="Emissão"
                    label-for="date_emission"
                  >
                    <b-form-datepicker
                      id="id_date_emission"
                      v-model="value.date_emission"
                      :required="true"
                      locale="pt-BR"
                      :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                      size="md"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      label-no-date-selected="Nenhuma data foi selecionada"
                      selected-variant="primary"
                      :disabled="!hasPermissionToUpdate"
                      placeholder="Selecione"
                      label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                      reset-button
                      reset-button-variant="info"
                      label-reset-button="Limpar"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                class="pl-50"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="date_validity"
                  :rules="{
                    required: true,
                  }"
                >
                  <b-form-group
                    label="Validade"
                    label-for="date_validity"
                  >
                    <b-form-datepicker
                      id="id_date_validity"
                      v-model="value.date_validity"
                      :required="true"
                      locale="pt-BR"
                      :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                      size="md"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      label-no-date-selected="Nenhuma data foi selecionada"
                      selected-variant="primary"
                      :disabled="!hasPermissionToUpdate"
                      placeholder="Selecione"
                      label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                      reset-button
                      reset-button-variant="info"
                      label-reset-button="Limpar"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <validation-provider
                  #default="validationContext"
                  name="link_certificates"
                >
                  <b-form-group
                    label="Link"
                    label-for="link_certificates"
                  >
                    <b-form-input
                      id="link_certificates"
                      v-model="value.link_certificates"
                      name="link_certificates"
                      :readonly="!hasPermissionToUpdate"
                      placeholder="https://"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <validation-provider
                  #default="validationContext"
                  name="annotation"
                >
                  <b-form-group
                    label="Anotações"
                    label-for="fileAnnotation"
                  >
                    <b-form-textarea
                      id="fileAnnotation"
                      v-model="value.annotation"
                      :state="getValidationState(validationContext)"
                      trim
                      :readonly="!hasPermissionToUpdate"
                      :placeholder="'Anotações'"
                    />

                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-button
              v-if="!value.file_name"
              v-b-tooltip.hover
              variant="outline-primary"
              size="sm"
              @click="selectFile()"
            >
              <input
                ref="fileInput"
                type="file"
                class="d-none"
                @change="handleFile"
              >
              <svg-icon
                type="mdi"
                :path="mdiFileUploadOutline"
              />
              <span
                class="font-weight-bolder"
              >
                Enviar Arquivo
              </span>
            </b-button>
            <b-button
              v-else
              v-b-tooltip.hover
              variant="outline-primary"
              size="sm"
              @click="downloadFile(value)"
            >
              <svg-icon
                type="mdi"
                :path="mdiFileDownloadOutline"
              />
              <span
                class="font-weight-bolder"
              >
                Baixar Arquivo
              </span>
            </b-button>
          </b-form>
        </b-modal>
      </validation-observer>
      <div>
        <b-button
          id="addCompanyDocumentBtn"
          v-b-tooltip.hover
          class="btn-icon rounded-circle m-3"
          variant="primary"
          size="md"
          :disabled="!hasPermissionToUpdate"
          title="Adicionar documento"
          @click="addDocument()"
        >
          <svg-icon
            type="mdi"
            :path="mdiPlus"
          />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BForm,
  BCardTitle,
  BCard,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BLink,
  BDropdown,
  BDropdownItem,
  BBadge,
  BFormDatepicker,
  BModal,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiPlus,
  mdiClipboardEditOutline,
  mdiFileDownloadOutline,
  mdiFileUploadOutline,
  mdiFileDocumentMultipleOutline,
  mdiTrashCanOutline,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import moment from 'moment'
import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import syslic from '@/syslic'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import SyncLoad from '@/components/utils/syncLoad'

export default {
  components: {
    BForm,
    BCardTitle,
    BCard,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BBadge,
    BFormDatepicker,
    BModal,
    SpinLoader,
    ValidationObserver,
    ValidationProvider,
    SvgIcon,
  },
  directives: {
    VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    const isAdd = true
    const companyDocuments = []
    const companyId = null
    const valueEmpty = {
      annotation: '',
      company: '',
      date_emission: '',
      date_validity: '',
      document_name: '',
      file_name: '',
      file: '',
      id: '',
      link_certificates: '',
    }

    const value = JSON.parse(JSON.stringify(valueEmpty))

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.value)

    const tableColumns = [
      {
        key: 'id',
        label: 'Id',
        thClass: 'contactHeader',
        sortable: false,
      },
      {
        key: 'field_document_name',
        label: 'Documento',
        thClass: 'contactHeader',
        sortable: false,
      },
      {
        key: 'field_status',
        label: 'Status',
        thClass: 'contactHeader',
        sortable: false,
      },
      {
        key: 'field_date_emission',
        label: 'Emissão',
        thClass: 'contactHeader',
        sortable: false,
      },
      {
        key: 'field_date_validity',
        label: 'Validade',
        thClass: 'contactHeader',
        sortable: false,
      },
      {
        key: 'field_link_certificates',
        label: 'Link para retirar as certidões',
        thClass: 'contactHeader',
        sortable: false,
      },
      {
        key: 'field_actions',
        thClass: 'contactHeader',
        label: '',
      },
    ]

    const isLoaded = false

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('document')

    syncLoad.onReady(() => {
      this.isLoaded = true
    })

    return {
      isAdd,
      valueEmpty,
      value,
      refFormObserver,
      getValidationState,
      tableColumns,
      isLoaded,
      companyDocuments,
      companyId,
      syncLoad,
      required,
    }
  },
  computed: {
    hasPermissionToRead() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.company.can_read
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.company.can_write
    },
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.company.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.company.can_delete
    },
  },
  watch: {
    'value.status_set': function updateStatus(value) {
      if (value) {
        this.value.status = value.id
      } else {
        this.value.status = ''
      }
    },
    'value.date_emission': function updateEmission(value) {
      if (value) {
        if (!this.checkDate()) {
          this.$nextTick(() => {
            this.value.date_emission = ''
          })
        }
      }
    },
    'value.date_validity': function updateValidity(value) {
      if (value) {
        if (!this.checkDate()) {
          this.$nextTick(() => {
            this.value.date_validity = ''
          })
        }
      }
    },
  },
  created() {
    this.companyId = router.currentRoute.params.id
    this.valueEmpty.company = this.companyId
    this.fetchData()
  },
  methods: {
    fetchData() {
      syslic
        .company
        .fetchCompany(this.companyId)
        .then(response => {
          this.companyDocuments = response.data.file_set
          this.syncLoad.ready('document')
        })
        .catch(error => {
          if (error.status === 404) {
            router.push({ name: 'error-404' })
          }
          this.syncLoad.ready('document')
        })
    },
    selectFile() {
      this.$refs.fileInput.click()
    },
    handleFile(event) {
      const selectedFile = event.target.files[0]
      if (selectedFile.size > 15000000) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Falha ao adicionar documento a empresa.',
            text:
              'O arquivo deve ter tamanho de até 15MB',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
            timeout: false,
          },
        })
        return
      }
      const reader = new FileReader()
      reader.onload = () => {
        this.value.file_name = selectedFile.name
        this.value.file = reader.result
      }
      reader.readAsDataURL(selectedFile)
    },
    deleteCompanyDocument(item) {
      syslic
        .company
        .file
        .deleteCompanyFile(item.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Documento removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.companyDocuments.splice(this.companyDocuments.indexOf(item), 1)
        })
        .catch(() => {
          const textError = 'Não foi possível remover este documento, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover documento.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    hasValidityExpired(dateValidity) {
      if (!dateValidity) {
        return false
      }

      const today = moment().startOf('day')
      const validityDate = moment(dateValidity)

      return validityDate.isBefore(today)
    },
    getCompanyStatus(date) {
      if (this.hasValidityExpired(date)) {
        return 'Vencido'
      }
      return 'Validado'
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover este documento.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteCompanyDocument(item)
          }
        })
    },
    add(val) {
      if (val.file === '') {
        this.$swal({
          title: 'Atenção!',
          text: 'Selecione um arquivo antes de salvar o documento.',
          icon: 'warning',
          showConfirmButton: false,
          timer: 4000,
        })

        return
      }

      syslic
        .company
        .file
        .addCompanyFile(val)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Documento adicionado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.fetchData()
        })
        .catch(() => {
          const textError = 'Não foi possível adicionar este documento, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar documento.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    update(id, val) {
      syslic
        .company
        .fileDetail
        .updateCompanyFileDetail(id, val)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Documento atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.fetchData()
        })
        .catch(() => {
          const textError = 'Não foi possível atualizar este documento, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar documento.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    save() {
      if (this.isAdd) {
        this.add(this.value)
      } else {
        this.update(this.value.id, this.value)
      }

      this.isAdd = true
    },
    cancel() {
      this.value = JSON.parse(JSON.stringify(this.valueEmpty))

      this.isAdd = true
    },
    onRowSelected(item) {
      this.value = JSON.parse(JSON.stringify(item))
      this.isAdd = false
      this.$refs.modalAddCompanyDocument.show()
    },
    addDocument() {
      this.value = JSON.parse(JSON.stringify(this.valueEmpty))
      this.isAdd = true
      this.$refs.modalAddCompanyDocument.show()
    },
    downloadFile(value) {
      syslic
        .company
        .file
        .fetchFile(value.id)
        .then(response => {
          const linkSource = `data:application/file;base64,${response.data.file}`
          const downloadLink = document.createElement('a')
          const fileName = this.value.file_name

          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha para realizar o download do documento da empresa.',
              text: 'Não foi possível realizar o download do documento da empresa, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    checkDate() {
      let valid = true
      if (this.value.date_emission && this.value.date_validity) {
        if (this.value.date_emission > this.value.date_validity) {
          valid = false
          this.$swal({
            title: 'Atenção!',
            text: 'A data de validade não pode ser anterior a data de emissão.',
            icon: 'warning',
            showConfirmButton: false,
            timer: 4000,
          })
        }
      }
      return valid
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      moment,
      mdiPlus,
      mdiClipboardEditOutline,
      mdiFileDownloadOutline,
      mdiFileUploadOutline,
      mdiFileDocumentMultipleOutline,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/base/core/colors/palette-variables.scss";

#addCompanyDocumentBtn {
  position: fixed;
  right: 0;
  bottom: 0;
}

#idModalAddCompanyDocument .vc-slider {
  width: 100% !important;
}

#idModalAddCompanyDocument .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: end;

  .btn{
    min-width: 107px;
  }
}

#fileAnnotation {
  min-height: 12.25rem;
}

.card-title {
  color: $primary
}

.contactHeader {
  background-color: #ffffff !important;
  border-bottom: 0 !important;
}

.contactHeader * {
  color: #5e5873
}
</style>
